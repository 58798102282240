import { PageProps } from "@/types";
import { useState, useEffect } from "react";
import { Head, usePage } from "@inertiajs/react";
import SupportLayout from './SupportPageLayout';
import {faqItems} from "@/Pages/Support/faqItems";

type FaqsProps = {
    params: Record<string, string>;
}

export default function FaqsPage({ auth, sessionFlash, params }: PageProps<FaqsProps>) {
    return (
        <SupportLayout>
            <>
                {
                    auth.user ?
                        <div className="pt-12 max-w-7xl mx-auto px-6 lg:px-8 space-y-6">
                            <div className="p-4 sm:p-8 bg-white shadow sm:rounded-lg">
                                <FaqsContent params={params} />
                            </div>
                        </div>
                        :
                        <FaqsContent params={params} />
                }
            </>
        </SupportLayout>
    )
}

// Helper function to create URL-friendly IDs from questions
const createIdFromQuestion = (question: string) => {
    return question
        .toLowerCase()
        .replace(/[^\w\s-]/g, '') // Remove special characters
        .replace(/\s+/g, '-') // Replace spaces with hyphens
        .replace(/-+/g, '-') // Remove consecutive hyphens
        .trim();
};

export function FaqsContent({ params }: FaqsProps) {
    const [activeIndices, setActiveIndices] = useState<Set<number>>(new Set());
    const page = usePage();

    // Process hash on initial load only (not on hash changes)
    useEffect(() => {
        const hash = window.location.hash.substring(1); // Remove the # symbol
        if (hash) {
            // Find the FAQ item that matches the hash
            const index = faqItems.findIndex(item =>
                createIdFromQuestion(item.question) === hash
            );

            if (index !== -1) {
                // Open only the item specified in the hash
                setActiveIndices(new Set([index]));
            } else {
                // If hash doesn't match any item, default to first item
                setActiveIndices(new Set([0]));
            }
        } else {
            // No hash present, default to first item
            setActiveIndices(new Set([0]));
        }
    }, []); // Empty dependency array means this only runs once on mount

    const toggleAccordion = (index: number) => {
        setActiveIndices(prevIndices => {
            const newIndices = new Set(prevIndices);
            if (newIndices.has(index)) {
                newIndices.delete(index);
                // We keep the URL hash even when closing an item
            } else {
                newIndices.add(index);
                // Update URL hash without triggering scroll or adding to history
                const id = createIdFromQuestion(faqItems[index].question);
                history.replaceState(null, "", `#${id}`);
            }
            return newIndices;
        });
    };

    const replaceParams = (str: string) => {
        return str.replace(/\${(\w+)}/g, (_, key) => params[key]);
    }

    const renderAnswer = (answer: string) => {
        const processedAnswer = replaceParams(answer);

        const parts = processedAnswer.split(/(?=\n[•-] |^[•-] )/);

        if (parts.length === 1) {
            return <p>{processedAnswer}</p>;
        }

        return (
            <div className="space-y-2">
                {parts.map((part, index) => {
                    const trimmedPart = part.trim();
                    if (trimmedPart.startsWith('•') || trimmedPart.startsWith('-')) {
                        return (
                            <div key={index} className="flex space-x-2 ml-2">
                                <span className="text-gray-400">•</span>
                                <span>{trimmedPart.substring(1).trim()}</span>
                            </div>
                        );
                    }
                    return trimmedPart && <p key={index}>{trimmedPart}</p>;
                })}
            </div>
        );
    };

    return (
        <div className="container mx-auto py-5 md:py-6 px-4 sm:px-6 lg:px-8">
            <Head title="FAQs">
                <meta name="description" content="Frequently asked questions" />
            </Head>
            <div className="space-y-4">
                {faqItems.map((faq, index) => {
                    const faqId = createIdFromQuestion(faq.question);
                    return (
                        <div
                            key={index}
                            id={faqId}
                            className={`${index < faqItems.length - 1 ? 'border-b' : ''} border-gray-200 pb-4`}
                        >
                            <button
                                className="flex justify-between items-center w-full text-left font-medium text-gray-900 group"
                                onClick={() => toggleAccordion(index)}
                                aria-expanded={activeIndices.has(index)}
                                aria-controls={`content-${faqId}`}
                            >
                                <span>{faq.question}</span>
                                <div className="flex items-center">
                                    <svg
                                        className={`w-5 h-5 transition-transform duration-300 ease-in-out ${
                                            activeIndices.has(index) ? 'transform rotate-180' : ''
                                        }`}
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={2}
                                            d="M19 9l-7 7-7-7"
                                        />
                                    </svg>
                                </div>
                            </button>
                            <div
                                id={`content-${faqId}`}
                                className={`text-gray-500 overflow-hidden transition-all duration-300 ease-in-out ${
                                    activeIndices.has(index) ? 'max-h-[1000px]' : 'max-h-0'
                                }`}
                            >
                                <div className="pt-4">
                                    {renderAnswer(faq.answer)}
                                    {faq.image && (
                                        <img src={faq.image} alt={faq.question} className="rounded-lg h-80 mt-5 shadow-md mb-10 border"  />
                                    )}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
