import {useEffect, useMemo, useRef, useState} from "react";
import axios from "axios";
import Spinner from "@/Components/Spinner";
import PayPalButton from "@/Components/PayPalButton";
import {faCreditCard} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import toast from "react-hot-toast";
import {PaymentStatus} from "@/types/enums";
import {MultiViewModal} from "@/Components/MultiViewModal";

export type PurchaseModalProps = {
    show: boolean;
    onClose: () => void;
    prices?: Record<string, number>;
    onCompleted?: () => void;
    message?: string;
};

export default function PurchaseModal({show, onClose, prices: initPrices, message, onCompleted}: PurchaseModalProps) {
    const [prices, setPrices] = useState(initPrices);
    const [selectedHours, setSelectedHours] = useState(10);
    const [customHours, setCustomHours] = useState<string | null>(null);
    const [lastCustomValue, setLastCustomValue] = useState<string>('');
    const [submitting, setIsSubmitting] = useState('');
    const [currentStep, setCurrentStep] = useState(0);
    const customInputRef = useRef<HTMLInputElement>(null);
    const isAsync = onCompleted !== undefined;

    const getHourRate = (hours: number) => {
        if (!prices) return 0;
        const tiers = Object.keys(prices).map(Number).sort((a, b) => b - a);
        return prices[tiers.find(tier => hours >= tier) || Math.min(...tiers)];
    };

    const effectiveHours = customHours ? parseInt(customHours) : selectedHours;

    const total = useMemo(() => {
        if (customHours !== null) {
            const hours = parseInt(customHours);
            if (!isNaN(hours) && hours > 0) {
                return hours * getHourRate(hours);
            }
            return 0;
        }
        return prices ? selectedHours * prices[selectedHours] : 0;
    }, [selectedHours, prices, customHours]);

    const getPrices = async () => {
        const result = await axios.get(route('credit.pricing'));
        setPrices(result.data.pricing);
    }

    useEffect(() => {
        if (show && !prices) {
            getPrices();
        }
    }, [show]);

    const initPaymentSession = async (payment_type: string) => {
        const minHours = Math.min(...Object.keys(prices || {}).map(Number))
        if (customHours && (isNaN(parseInt(customHours)) || parseInt(customHours) < minHours)) {
            toast.error('Custom hours must be at least ' + minHours);
            return;
        }

        setIsSubmitting(payment_type)
        const origin = window.location.pathname + window.location.search;
        const params = {
            hours: effectiveHours,
            origin,
            "async": isAsync,
            payment_type: payment_type,
            custom_rate: !!customHours
        };

        try {
            const result = await axios.post(route('payment.charge'), params);
            const {url, session_id} = result.data;
            if (isAsync) {
                let childWindow = window.open(url, '_blank');
                if (childWindow) {
                    await waitForPaymentFlow(childWindow);
                    const status = await checkPaymentStatus(session_id) as PaymentStatus;
                    if (status === PaymentStatus.COMPLETED) {
                        toast.success('Payment completed');
                        onCompleted();
                    } else if (status === PaymentStatus.PENDING) {
                        toast.success('Payment pending. Refresh the page to see the updated status');
                    }
                    setIsSubmitting('')
                } else {
                    window.location.href = url;
                }
            } else {
                window.location.href = url;
            }
        } catch (error: any) {
            const message = error?.response?.data?.message || 'An error occurred while initializing the payment';
            toast.error(message);
            setIsSubmitting('');
        }
    }

    const waitForPaymentFlow = async (childWindow: Window): Promise<void> => {
        return new Promise((resolve) => {
            const interval = setInterval(() => {
                if (childWindow.closed) {
                    clearInterval(interval);
                    resolve()
                }
            }, 1000);
        })
    }

    const handleCustomHoursChange = (value: string) => {
        setCustomHours(value);
        setLastCustomValue(value);
        setSelectedHours(0); // Clear preset selection
    }

    const handleCustomHoursBlur = () => {
        if (customHours === '') return;

        const minHours = Math.min(...Object.keys(prices || {}).map(Number));
        const numValue = parseInt(customHours || '0');

        if (isNaN(numValue) || numValue < minHours) {
            setCustomHours(minHours.toString());
        }
    }

    const checkPaymentStatus = async (sessionId: string) => {
        const url = route('payment.status', {session_id: sessionId});
        const result = await axios.get(url);
        return result.data.status;
    }

    useEffect(() => {
        setIsSubmitting('')
    }, [show]);

    useEffect(() => {
        if (customHours !== null && customInputRef.current) {
            customInputRef.current.focus();
        }
    }, [customHours]);

    const SelectHoursView = (
        <div className="bg-white mt-5 mb-1 p-6 rounded-lg w-full mx-auto">
            {Object.keys(prices || {}).map((key) => (
                <div key={key} className="flex mb-4 items-center">
                    <div className="flex items-center h-5">
                        <input
                            checked={selectedHours === parseInt(key) && customHours === null}
                            onChange={() => {
                                if (customHours !== null) {
                                    setLastCustomValue(customHours);
                                }
                                setSelectedHours(parseInt(key));
                                setCustomHours(null);
                            }}
                            id={`radio-${key}`}
                            type="radio"
                            name="priceOption"
                            value={key}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                    </div>
                    <div className="ml-2 text-sm flex justify-between w-full">
                        <label htmlFor={`radio-${key}`}
                               className="font-medium text-gray-900 dark:text-gray-300 flex-grow">
                            {key} Extraction hours
                        </label>
                        <p className="text-xs font-normal text-gray-500 dark:text-gray-300 text-left">
                            ${prices![key].toFixed(2)} / hour
                        </p>
                    </div>
                </div>
            ))}

            {/* Custom hours option */}
            <div className="mb-4">
                <div className="flex items-center">
                    <div className="flex items-center h-5">
                        <input
                            checked={customHours !== null}
                            onChange={() => {
                                if (customHours === null) {
                                    const minHours = Math.min(...Object.keys(prices || {}).map(Number));
                                    setCustomHours(lastCustomValue || minHours.toString());
                                    setSelectedHours(0);
                                }
                            }}
                            id="radio-custom"
                            type="radio"
                            name="priceOption"
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                        />
                    </div>
                    <div className="ml-2 text-sm grow">
                        <div className="flex w-full flex-1">
                            <label htmlFor="radio-custom"
                                   className="font-medium text-gray-900 dark:text-gray-300 flex-grow">
                                Custom Amount
                            </label>
                            {
                                customHours === null && (
                                    <p className="text-xs font-normal text-gray-400 dark:text-gray-300 text-left">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             strokeWidth={1.5} stroke="currentColor" className="size-4">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                  d="M6.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM18.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"/>
                                        </svg>

                                    </p>
                                )
                            }
                        </div>
                    </div>
                </div>

                {customHours !== null && (
                    <div className="mt-4 flex items-center">
                        <input
                            type="number"
                            ref={customInputRef}
                            value={customHours}
                            onChange={(e) => handleCustomHoursChange(e.target.value)}
                            onBlur={handleCustomHoursBlur}
                            min={Math.min(...Object.keys(prices || {}).map(Number))}
                            max={500}
                            placeholder="Enter hours"
                            className="w-16 px-2 p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500 "
                        />
                        <div className="flex flex-col">
                            <span className="ml-2 text-sm">Extraction hours</span>
                            <p className="text-xs font-normal text-gray-500 ml-2">
                                ${getHourRate(parseInt(customHours)).toFixed(2)} /
                                hour
                            </p>
                        </div>
                    </div>
                )}
            </div>

            <button
                onClick={() => setCurrentStep(1)}
                className="w-full mt-4 bg-blue-600 text-white py-3 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50">
                Next
            </button>
        </div>
    );

    const PaymentMethodView = (
        <div className="bg-white mt-5 mb-1 p-6 rounded-lg w-full mx-auto">
            <div className="flex flex-col space-y-4">
                <button
                    disabled={submitting !== ''}
                    onClick={() => initPaymentSession('stripe')}
                    className={`w-full flex justify-center items-center bg-blue-600 text-white py-3 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 ${submitting === 'stripe' ? 'bg-gray-500 hover:bg-gray-500' : ''}`}>
                    <FontAwesomeIcon
                        className="text-gray-300 w-4 h-4 pr-2 py-1"
                        icon={faCreditCard}/>
                    <span>Credit Card</span>
                    {submitting === 'stripe' && <Spinner className="ml-2"/>}
                </button>

                <PayPalButton
                    disabled={submitting !== ''}
                    spinner={submitting === 'paypal'}
                    onClick={() => initPaymentSession('paypal')}
                />

                <button
                    disabled={submitting !== ''}
                    onClick={() => initPaymentSession('coinbase')}
                    className={`w-full flex justify-center items-center bg-blue-800 text-white py-3 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 ${submitting === 'coinbase' ? 'bg-gray-500 hover:bg-gray-500' : ''}`}>
                    <svg
                        role="img"
                        aria-label="ock-coinbasePaySvg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        className="mr-1"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M10.0145 14.1666C7.82346 14.1666 6.04878 12.302 6.04878 9.99996C6.04878 7.69788 7.82346 5.83329 10.0145 5.83329C11.9776 5.83329 13.6069 7.33677 13.9208 9.30552H17.9163C17.5793 5.02774 14.172 1.66663 10.0145 1.66663C5.63568 1.66663 2.08301 5.39926 2.08301 9.99996C2.08301 14.6007 5.63568 18.3333 10.0145 18.3333C14.172 18.3333 17.5793 14.9722 17.9163 10.6944H13.9208C13.6069 12.6632 11.9776 14.1666 10.0145 14.1666Z"
                            fill="#f9fafb"
                        />
                    </svg>
                    <span>Crypto</span>
                    {submitting === 'coinbase' && <Spinner className="ml-2"/>}
                </button>
            </div>
        </div>
    );

    return (
        <MultiViewModal
            maxWidth="sm"
            show={show}
            navBarClassName='px-4 pt-4'
            onClose={onClose}
            currentIdx={currentStep}
            onBack={() => setCurrentStep(0)}
        >
            <div title="Buy more credits">
                <div className="flex flex-col items-center justify-center">

                    <h4 className="font-bold text-2xl mt-2">${total.toFixed(2)}</h4>
                </div>
                <hr className="my-5 mx-5"/>
                {SelectHoursView}
            </div>
            <div title="Buy more credits">
                <div className="flex flex-col items-center justify-center">
                    <h4 className=" mt-7 text-xl font-bold">${total.toFixed(2)}</h4>
                    <span className="">{effectiveHours} extraction hours</span>
                </div>

                {PaymentMethodView}
            </div>
        </MultiViewModal>
    );
}
