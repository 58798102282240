import {useEffect, useMemo, useState} from "react";
import PrimaryButton from "@/Components/PrimaryButton";
import {faGear, faMagicWandSparkles} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ucFirst} from "@/utils/textFormatting";
import {SubtitlePosition} from "@/types";
import VideoProbe from "@/services/video/VideoProbe";
import {MultiViewModal} from "@/Components/MultiViewModal";
import SubtitleRegionConfig from "@/Pages/Dashboard/Partials/SubtitleRegionSelect";
import {ToggleSwitch} from "flowbite-react";
import {VideoMetaData} from "@/services/video/FFmpeg";
import Spinner from "@/Components/Spinner";

export type AdvancedSettings = {
    position: SubtitlePosition,
    yOffsetRatio?: [number, number],
    xOffsetRatio?: [number, number],
    softCodedSubtitles: boolean,
}

export type AdvancedSettingsModalProps = {
    show: boolean,
    onClose: () => void,
    onSave: (settings: AdvancedSettings) => void,
    initSettings?: AdvancedSettings,
    videoFile?: File,
}

export const DEFAULT_SUBTITLE_POSITION = 'auto';

const DEFAULT_SETTINGS: AdvancedSettings = {
    position: DEFAULT_SUBTITLE_POSITION,
    softCodedSubtitles: true,
}

export default function AdvancedSettingsModal({
    show,
    onClose,
    onSave,
    initSettings,
    videoFile
}: AdvancedSettingsModalProps) {
    initSettings = Object.assign({}, DEFAULT_SETTINGS, initSettings || {});
    const [position, setPosition] = useState<SubtitlePosition>(initSettings.position);
    const [videoMetadata, setVideoMetadata] = useState<null | VideoMetaData>(null);
    const [selectingCustomRegion, setSelectingCustomRegion] = useState(false);
    const [yOffset, setYOffset] = useState<undefined | [number, number]>(initSettings.yOffsetRatio);
    const [xOffset, setXOffset] = useState<undefined | [number, number]>(initSettings.xOffsetRatio);
    const [softCodedSubtitles, setSoftCodedSubtitles] = useState(initSettings.softCodedSubtitles);
    const canSelectCustomRegion = useMemo(() => {
        return videoMetadata !== null && videoMetadata.subtitles.length === 0 || !softCodedSubtitles
    }, [videoMetadata, softCodedSubtitles]);
    const hasSoftCodedSubtitles = useMemo(() => videoMetadata?.subtitles.length !== 0, [videoMetadata]);

    const save = () => {
        onSave({position, yOffsetRatio: yOffset, xOffsetRatio: xOffset, softCodedSubtitles});
        onClose();
    }

    useEffect(() => {
        if (show && videoFile) {
            const videoProbe = new VideoProbe();
            videoProbe.load(videoFile).then(() => {
                setVideoMetadata(videoProbe.getMetaData());
            }).catch(_ => {
                setVideoMetadata({duration: 0, height: 0, width: 0, subtitles: []});
            }).finally(() => {
                videoProbe.unload();
            })
        } else if (!videoFile) {
            setVideoMetadata(null);
        }
    }, [show, videoFile]);

    useEffect(() => {
        if (!show) {
            setTimeout(() => setSelectingCustomRegion(false), 350);
            setYOffset(undefined);
            setXOffset(undefined);
        }
    }, [show]);

    useEffect(() => {
        if (show) {
            setPosition(initSettings.position);
            setSoftCodedSubtitles(initSettings.softCodedSubtitles);
            setYOffset(initSettings.yOffsetRatio);
            setXOffset(initSettings.xOffsetRatio);
        }
    }, [show]);

    const handleRegionSelection = (xStart: number, xEnd: number, yStart: number, yEnd: number) => {
        setYOffset([yStart, yEnd]);
        setXOffset([xStart, xEnd]);
        setPosition('custom');
        setSelectingCustomRegion(false);
    }

    return (<MultiViewModal
        currentIdx={selectingCustomRegion ? 1 : 0}
        onBack={() => setSelectingCustomRegion(false)}
        show={show}
        onClose={onClose}
        maxWidth={'md'}
        title="Advanced Settings"
    >
        {videoMetadata === null ? (
            <div className="w-full flex justify-center items-center min-h-60">
                <Spinner size={8} className="ml-2 fill-blue-300"/>
            </div>
        ) : (
            <div className="px-8 pb-6 mt-5">
                {hasSoftCodedSubtitles && (
                    <div>
                        <div className="mb-0 mt-5">
                            <label htmlFor="default-input" className="block mb-1 text-sm font-black text-gray-900 dark:text-white">
                                Soft-coded Subtitles
                            </label>
                            <div className="flex flex-row items-center justify-between">
                                <p className="text-sm text-gray-600">Extract embedded subtitle track from the file. Disable to extract from video frames instead.</p>
                                <div className="flex-shrink-0 ml-4 mr-3">
                                    <ToggleSwitch
                                        color="blue"
                                        theme={{
                                            toggle: {
                                                base: "toggle-bg rounded-full border-gray-200 bg-gray-200 dark:border-gray-600 dark:bg-gray-700 w-11 h-6 after:absolute after:top-[2px] after:left-[2px] after:h-5 after:w-5",
                                                checked: { color: { blue: "bg-blue-700 border-blue-700" } },
                                            },
                                        }}
                                        checked={softCodedSubtitles}
                                        onChange={setSoftCodedSubtitles}
                                    />
                                </div>
                            </div>
                        </div>
                        {canSelectCustomRegion && <hr className="my-6"/>}
                    </div>
                )}
                {canSelectCustomRegion && (
                    <div>
                        <div className="mb-5">
                            <label htmlFor="default-input" className="block mb-1 text-sm font-black text-gray-900 dark:text-white">
                                Subtitle Region
                            </label>
                            <p className="text-sm text-gray-600">Choose the region of the video frame from which subtitles will be extracted.</p>
                        </div>
                        <div className="flex">
                            <SubtitlePositionButton position='auto' selected={position === 'auto'} onClick={() => setPosition('auto')}/>
                            <SubtitlePositionButton position='bottom' selected={position === 'bottom'} onClick={() => setPosition('bottom')}/>
                            <SubtitlePositionButton position='middle' selected={position === 'middle'} onClick={() => setPosition('middle')}/>
                            <SubtitlePositionButton position='top' selected={position === 'top'} onClick={() => setPosition('top')}/>
                            {videoFile && (
                                <SubtitlePositionButton position='custom' selected={position === 'custom'} onClick={() => setSelectingCustomRegion(true)}/>
                            )}
                        </div>
                    </div>
                )}
                <PrimaryButton className="w-full mt-12 mb-4" onClick={save}>
                    <span className="flex justify-center items-center">Save</span>
                </PrimaryButton>
            </div>
        )}
        <div title="Select Subtitle Region">
            {videoFile && selectingCustomRegion && (
                <SubtitleRegionConfig
                    onSelection={handleRegionSelection}
                    onClose={() => setSelectingCustomRegion(false)}
                    offsetRatio={xOffset && yOffset ? [xOffset[0], xOffset[1], yOffset[0], yOffset[1]] : undefined}
                    videoFile={videoFile}
                />
            )}
        </div>
    </MultiViewModal>)
}

type SubtitlePositionButtonProps = {
    position: SubtitlePosition,
    selected: boolean,
    onClick: () => void,
}

function SubtitlePositionButton({position, selected, onClick}: SubtitlePositionButtonProps) {
    let justifyContent = 'justify-center';
    let padding = '';

    if (position === 'top') {
        justifyContent = 'justify-start';
        padding = 'pt-2';
    } else if (position === 'bottom') {
        justifyContent = 'justify-end';
        padding = 'pb-2';
    }

    return (
        <div
            onClick={onClick}
            className={`cursor-pointer ${position === 'custom' ? 'hidden lg:flex' : ''} bg-white text-gray-900 w-1/6 flex-col text-center mr-2`}
        >
            <div
                className={`transition-all bg-gray-100 ${selected ? 'border-2 border-blue-600' : 'border-2 border-white hover:border-gray-300'} rounded w-full h-20 lg:h-14 flex flex-col items-center ${justifyContent} ${padding}`}
            >
                {position === 'auto' ? (
                    <FontAwesomeIcon icon={faMagicWandSparkles} className="text-gray-400 text-xl"/>
                ) : position === 'custom' ? (
                    <FontAwesomeIcon icon={faGear} className="text-gray-400 text-xl"/>
                ) : (
                    <>
                        <div className="h-1 bg-gray-300 rounded-full w-[80%]"></div>
                        <div className="h-1 bg-gray-300 rounded-full w-[60%] mt-1"></div>
                    </>
                )}
            </div>
            <span className="text-sm mt-2">{ucFirst(position)}</span>
        </div>
    )
}
