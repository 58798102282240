import React, { createContext, useContext, useState, useCallback } from 'react';
import Modal from '@/Components/Modal';
import SecondaryButton from '@/Components/SecondaryButton';
import DangerButton from '@/Components/DangerButton';

interface ConfirmOptions {
    title?: string;
    message?: string;
    confirmText?: string;
    cancelText?: string;
    children?: React.ReactNode;
}

interface ConfirmState extends ConfirmOptions {
    isOpen: boolean;
    onConfirm?: () => void;
    onCancel?: () => void;
}

interface ConfirmContextValue {
    confirm: ConfirmFunction;
    isOpen: boolean;
}

type ConfirmParameter = ConfirmOptions | string;
type ConfirmFunction = (options: ConfirmParameter) => Promise<boolean>;

const ConfirmContext = createContext<ConfirmContextValue | null>(null);

export const ConfirmProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [state, setState] = useState<ConfirmState>({ isOpen: false });

    const confirmFunction = useCallback((options: ConfirmParameter) => {
        const normalizedOptions: ConfirmOptions = typeof options === 'string'
            ? { message: options }
            : options;

        return new Promise<boolean>((resolve) => {
            setState({
                isOpen: true,
                ...normalizedOptions,
                onConfirm: () => {
                    setState(prev => ({ ...prev, isOpen: false }));
                    resolve(true);
                },
                onCancel: () => {
                    setState(prev => ({ ...prev, isOpen: false }));
                    resolve(false);
                }
            });
        });
    }, []);

    const contextValue = {
        confirm: confirmFunction,
        isOpen: state.isOpen,
    };

    return (
        <ConfirmContext.Provider value={contextValue}>
            {children}
            <Modal show={state.isOpen} onClose={state.onCancel || (() => {})} maxWidth="md">
                <div className="p-6">
                    <h2 className="text-lg font-medium text-gray-900">
                        {state.title || 'Confirm Action'}
                    </h2>

                    <p className="mt-1 text-sm text-gray-600">
                        {state.message || 'Are you sure you want to continue?'}
                    </p>

                    {state.children && (
                        <div className="mt-4">
                            {state.children}
                        </div>
                    )}

                    <div className="mt-6 flex justify-end">
                        <SecondaryButton onClick={state.onCancel}>
                            {state.cancelText || 'Cancel'}
                        </SecondaryButton>

                        <DangerButton onClick={state.onConfirm}>
                            {state.confirmText || 'Confirm'}
                        </DangerButton>
                    </div>
                </div>
            </Modal>
        </ConfirmContext.Provider>
    );
};

export const useConfirm = () => {
    const context = useContext(ConfirmContext);

    if (!context) {
        throw new Error('useConfirm must be used within a ConfirmProvider');
    }

    return context.confirm
};

export const useIsConfirmOpen = () => {
    const context = useContext(ConfirmContext);

    if (!context) {
        throw new Error('useIsConfirmOpen must be used within a ConfirmProvider');
    }

    return context.isOpen;
};
