import React, {useEffect, useState} from 'react';
import { PaginatedSource } from "@/hooks/usePaginatedSource";

type PaginationControlProps = {
    source: PaginatedSource<any>;
    perPageOptions?: number[];
}

export default function PaginationControl({
    source,
    perPageOptions = [15, 25, 50],
}: PaginationControlProps) {
    const {pagination, nextPage, prevPage, isLoading, setPerPage} = source;
    const [lastClicked, setLastClicked] = useState<'prev' | 'next' | null>(null);

    const onNavigate = (isNext: boolean) => {
        setLastClicked(isNext ? 'next' : 'prev');
        if (isNext) {
            nextPage();
        } else {
            prevPage();
        }
    }

    const handlePerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setPerPage(Number(event.target.value));
    };

    return (
        <div className="grow flex items-center">
            <span className="shrink-0 text-xs text-gray-800">
                Page {pagination.currentPage} of {pagination.lastPage}
            </span>

            <div className="flex-grow flex justify-center">
                <div className="flex rounded-lg shadow-sm ring-1 transition duration-75 bg-white border-gray-200 ring-gray-200">
                    <div className="flex items-center gap-x-3 border-e border-gray-200 pe-1 ps-2 md:pe-3 md:ps-3">
                        <span className="whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                            Per page
                        </span>
                    </div>
                    <div className="min-w-0 flex-1">
                        <select
                            value={pagination.perPage}
                            onChange={handlePerPageChange}
                            className="block w-full border-none bg-transparent py-1.5 pe-8 text-xs md:text-sm text-gray-950 transition duration-75 focus:ring-0 disabled:text-gray-500 sm:text-sm sm:leading-6 md:ps-3"
                            disabled={isLoading}
                        >
                            {perPageOptions.map(option => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>

            <div className="shrink-0 flex justify-end md:gap-2">
                <button
                    onClick={() => onNavigate(false)}
                    disabled={pagination.currentPage === 1 || isLoading}
                    className={`flex items-center justify-center px-2 md:px-3 h-7 md:h-8 text-sm font-medium border bg-white transition-colors duration-200
            ${pagination.currentPage === 1 || isLoading
                        ? 'text-gray-300 border-gray-200 cursor-not-allowed'
                        : 'text-gray-500 border-gray-300 hover:bg-gray-100 hover:text-gray-700'
                    }
            md:rounded-lg rounded-none first:rounded-l-md md:first:rounded-lg`}
                >
                    <svg
                        className="w-3.5 h-3.5 rtl:rotate-180"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 14 10"
                    >
                        <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M13 5H1m0 0 4 4M1 5l4-4"
                        />
                    </svg>
                    <span className="hidden md:inline ms-2">Previous</span>
                    {isLoading && lastClicked === 'prev' && (
                        <div className="w-3 h-3 border-2 border-gray-300 border-t-gray-500 rounded-full animate-spin ml-2"></div>
                    )}
                </button>

                <button
                    onClick={() => onNavigate(true)}
                    disabled={pagination.currentPage === pagination.lastPage || isLoading}
                    className={`flex items-center justify-center px-2 md:px-3 h-7 md:h-8 text-sm font-medium border bg-white transition-colors duration-200
            ${pagination.currentPage === pagination.lastPage || isLoading
                        ? 'text-gray-300 border-gray-200 cursor-not-allowed'
                        : 'text-gray-500 border-gray-300 hover:bg-gray-100 hover:text-gray-700'
                    }
            md:rounded-lg rounded-none last:rounded-r-md md:last:rounded-lg border-l-0 md:border-l`}
                >
                    {isLoading && lastClicked === 'next' && (
                        <div className="w-3 h-3 border-2 border-gray-300 border-t-gray-500 rounded-full animate-spin mr-2"></div>
                    )}
                    <span className="hidden md:inline me-2">Next</span>
                    <svg
                        className="w-3.5 h-3.5 rtl:rotate-180"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 14 10"
                    >
                        <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M1 5h12m0 0L9 1m4 4L9 9"
                        />
                    </svg>
                </button>
            </div>
        </div>
    )
}
