interface FAQItem {
    question: string;
    image?: string;
    answer: string;
}

export const faqItems: FAQItem[] = [
    {
        question: 'Can I extract subtitles for free?',
        answer: `Yes, you get $\{free_hours} hours of free subtitle extraction when you sign up. This means you can extract subtitles from videos with a total duration of up to $\{free_hours} hours. If you need more, you can purchase additional processing time for as low as $0.25 per hour of video duration. There are no recurring expenses or subscriptions.`,
    },
    {
        question: 'What are hardcoded or burned-in subtitles?',
        answer: 'Hardcoded or burned-in subtitles are subtitle text permanently embedded into the video frames. They cannot be turned off or removed separately from the video.',
    },
    {
        question: 'Can I extract soft-coded subtitles?',
        answer: 'Yes, we can extract soft-coded subtitles that are part of the video file but not permanently embedded within the image frames. Unlike hardcoded subtitles, soft-coded subtitles can be turned on or off by the viewer, similar to using the option to toggle subtitles with your TV remote.',
    },
    {
        question: 'Can I create subtitles for videos that don\'t already have them?',
        answer: 'Yes, we offer two methods to generate subtitles for videos without existing subtitles. The vision method uses optical character recognition (OCR) to detect and extract any visible text within the video frames. The audio method employs speech recognition technology to transcribe the spoken dialogue from the video\'s audio track into subtitle text.',
    },
    {
        question: 'How long do you keep my uploaded videos?',
        answer: 'We delete all uploaded videos after 48 hours to protect your privacy.',
    },
    {
        question: 'How long does subtitle extraction take?',
        answer: 'On average, subtitle extraction takes about 5 minutes per video. However, this can vary depending on the video\'s duration and complexity.',
    },
    {
        question: 'Why are my extracted subtitles empty or missing parts of the text?',
        answer: 'There are several common reasons why subtitle extraction might return incomplete or empty results:\n\n' +
            '• If you used the vision method but your video doesn\'t contain visible subtitles in the frames, try using the audio method instead to transcribe the speech.\n\n' +
            '• If your video\'s subtitles appear in different positions (like sometimes at the top, sometimes at the bottom), this can affect extraction since our system is designed to extract from one consistent region.\n\n' +
            '• By default, if your video contains soft-coded subtitles, our system will extract those instead of looking for hardcoded subtitles in the frames. If you specifically want to extract hardcoded subtitles, you can disable soft-coded subtitle extraction in the advanced options by clicking the settings ⚙ icon next to your video after uploading it.',
    },
    {
        question: 'Can I extract subtitles from YouTube or other online videos?',
        answer: 'Yes, there are two ways to extract subtitles from online videos. You can download the video and then upload it to our platform, which can be done using the free trial credits. Alternatively, you can import the video URL directly into our system, which is faster and easier but requires purchased processing credits.',
    },
    {
        question: 'Is there a size limit for video uploads?',
        answer: 'The maximum file size for video uploads is 12GB for paid users and 5GB for free users. If you have larger files, please contact us, and we\'ll do our best to assist you. While there is no limit on the video duration, there is a minimum video duration requirement of 5 minutes. For paid users, there is no minimum duration. You become a paid user when you purchase any amount of credits.',
    },
    {
        question: 'How can I limit the region of the frame where subtitles are extracted from?',
        image: '/img/custom-subtitle-region.png',
        answer: 'You can limit the region where subtitles are extracted from by clicking the settings ⚙ icon next to your video after uploading it. In the "Advanced Settings", under "Subtitle Region" select "Custom". This allows you to specify the exact bounds to extract subtitles from, which is useful if your video has persistent UI elements, logos, or footer text you want to exclude.',
    }
];
