import moment from "moment/moment";
import {Link, router} from "@inertiajs/react";
import {bytesToSize, getLanguageName, secondsToDuration, ucFirst} from "@/utils/textFormatting";
import {cancelJob, deleteJob, ExtractionJobStatus} from "@/services/extractionJobManager";
import {Job, PersistedJob} from "@/types";
import toast from "react-hot-toast";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowAltCircleDown as faCircleDown,
    faMessage,
    faPenToSquare as faPen,
    faTrashCan
} from "@fortawesome/free-regular-svg-icons";
import {faCircleXmark, faDownload, faEllipsis, faRotateRight, faXmark} from "@fortawesome/free-solid-svg-icons";
import JobStatusIndicator from "@/Pages/Dashboard/Partials/JobStatusIndicator";
import Dropdown from "@/Components/Dropdown";
import {Tooltip} from "flowbite-react";
import Checkbox from "@/Components/Checkbox";
import React, {useEffect, useState} from "react";
import SecondaryButton from "@/Components/SecondaryButton";
import Popover from "@/Components/Popover";
import {PaginatedSource} from "@/hooks/usePaginatedSource";
import PaginationControl from "@/Components/PaginationControl";
import axios from "axios";
import TranslateIcon from "@/Components/Icons/TranslateIcon";
import {bulkDownloadSubtitles} from "@/services/subtitleService";
import {useConfirm} from "@/Components/ConfirmDialog";

export type ExtractionJobsTableProps = {
    showTranslateTip?: boolean
    paginatedJobs: PaginatedSource<PersistedJob>
    initialRows: number
    onFeedback?: (job: PersistedJob) => void
    onRetry: (job: PersistedJob) => void
    onBulkTranslate: (jobs: PersistedJob[]) => void
}

const perPageOptions = [15, 25, 50]

export default function ExtractionJobsTable({
    showTranslateTip,
    paginatedJobs,
    initialRows,
    onFeedback,
    onRetry,
    onBulkTranslate
}: ExtractionJobsTableProps) {
    const {
        items: jobs,
        updateItems: setJobs,
        reloadItems: reloadJobs,
        isLoading,
        pagination,
        error
    } = paginatedJobs
    const [initialized, setInitialized] = useState(false)
    const confirm = useConfirm()
    const [checked, setChecked] = useState<Set<string>>(new Set())
    const [lastCheckedId, setLastCheckedId] = useState<string | null>(null);
    const [tooltipOpen, setTooltipOpen] = useState(false)
    const numChecked = checked.size

    useEffect(() => {
        if (showTranslateTip) {
            setTooltipOpen(true)
        }
    }, [showTranslateTip]);

    const closeToolTip = (e?: any) => {
        setTooltipOpen(false)
        e?.stopPropagation()
    }

    useEffect(() => {
        if (!initialized) {
            setInitialized(true);
        } else {
            const rafId = requestAnimationFrame(() => {
                window.scrollTo({top: 0, behavior: 'smooth'});
            });
            return () => cancelAnimationFrame(rafId);
        }

    }, [pagination.currentPage]);

    const handleDelete = async (job: Job) => {
        if (await confirm({ title: 'Delete', message: 'Are you sure you want to delete?'})) {
            await toast.promise(
                deleteJob(job),
                {
                    loading: 'Deleting...',
                    success: 'File deleted',
                    error: (err) => err.message
                }
            )
            await deleteJob(job)
            reloadJobs();
        }
    }

    const handleBulkDelete = async () => {
        if (await confirm({ title: 'Bulk Delete', message: `Are you sure you want to delete ${checked.size} files?`})) {
            await toast.promise(
                axios.post(route('job.bulk-delete', {ids: Array.from(checked)})),
                {
                    loading: 'Deleting...',
                    success: 'Files deleted',
                    error: (err) => err.message
                }
            )
            reloadJobs();
        }
    }

    const bulkDownload = async () => {
        bulkDownloadSubtitles(Array.from(checked))
    }

    const bulkTranslate = async () => {
        onBulkTranslate(
            jobs.filter(job => checked.has(job.id))
                .filter(job => job.status === ExtractionJobStatus.Succeeded)
        )
    }

    const launchTranslation = (job: PersistedJob) => {
        router.visit(route('subtitle.edit', job), {
            data: {translate: true}
        })
    }

    const handleCancellation = async (job: Job) => {
        if (!(await confirm({title: 'Cancel Job', message: 'Are you sure you want to cancel?'}))) {
            return
        }
        cancelJob(job).then(() => {
            toast.success('Job cancelled');
            return reloadJobs()
        }).then().catch(err => {
            toast.error(err.message)
        })
    }

    const toggleChecked = () => {
        if (checked.size === jobs!!.length) {
            setChecked(new Set())
        } else {
            const newChecked = new Set<string>()
            jobs!!.forEach(job => {
                newChecked.add(job.id)
            })
            setChecked(newChecked)
        }
    }

    const toggleJobChecked = (job: PersistedJob, event: Event) => {
        const newChecked = new Set(checked);

        if ('shiftKey' in event && event.shiftKey && lastCheckedId !== null) {
            const currentIndex = jobs.findIndex(j => j.id === job.id);
            const lastIndex = jobs.findIndex(j => j.id === lastCheckedId);

            const startIdx = Math.min(currentIndex, lastIndex);
            const endIdx = Math.max(currentIndex, lastIndex);

            for (let i = startIdx; i <= endIdx; i++) {
                newChecked.add(jobs[i].id);
            }
        } else {
            newChecked.has(job.id) ? newChecked.delete(job.id) : newChecked.add(job.id);
        }

        setLastCheckedId(job.id);
        setChecked(newChecked);
    }

    const hasPages = paginatedJobs.pagination.total >= perPageOptions[0];
    const isLoadingRows = isLoading && !jobs.length;
    return (
        <div className="">
            <div className="flex flex-col mt-6">
                <div className="overflow-x-auto">
                    <div className="inline-block min-w-full align-middle">
                        <div className="overflow-hidden">
                            <table className="min-w-full divide-y  table-auto">
                                <thead className="">
                                <tr>
                                    <th scope="col"
                                        className="hidden md:table-cell py-4 pl-4 text-left">
                                        <Checkbox onChange={toggleChecked}/>
                                    </th>
                                    <th scope="col"
                                        className="p-2 md:p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase ">
                                        File name
                                    </th>
                                    <th scope="col"
                                        className="p-2 md:p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                        Status
                                    </th>
                                    <th scope="col"
                                        className="p-2 md:p-4 hidden md:table-cell text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                                        Method
                                    </th>
                                    <th scope="col"
                                        className="p-2 md:p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase ">
                                        Duration
                                    </th>
                                    <th scope="col"
                                        className="hidden lg:table-cell p-2 md:p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase ">
                                        File size
                                    </th>
                                    <th scope="col"
                                        className="p-2 md:p-4 hidden lg:table-cell text-xs font-medium tracking-wider text-left text-gray-500 uppercase ">
                                        Language
                                    </th>
                                    <th scope="col"
                                        className="p-2 md:p-4 text-xs hidden xl:table-cell font-medium tracking-wider text-left text-gray-500 uppercase">
                                        Date
                                    </th>
                                    <th scope="col"
                                        className="p-2 md:p-4 text-xs font-medium tracking-wider text-left text-gray-500 uppercase ">

                                    </th>
                                </tr>
                                </thead>
                                <tbody className={`${isLoadingRows ? 'divide-y divide-gray-200 animate-pulse' : ''}`}>
                                {
                                    isLoadingRows && Array.from({length: initialRows}, (_, idx) => <SkeletonTableRow
                                        key={idx}/>)
                                }
                                {
                                    jobs.map((job,
                                        index) => (
                                        <tr key={job.id}
                                            className={`border hover:bg-gray-50 border-bottom-gray-200 border-l-0 border-r-0 ${!hasPages ? 'border-b-0' : ''}`}>
                                            <td className="py-4 pl-4 hidden md:table-cell">
                                                <Checkbox onChange={(e) => toggleJobChecked(job, e.nativeEvent)}
                                                          checked={checked.has(job.id)}/>
                                            </td>
                                            <td className="max-w-25 md:max-w-sm text-xs md:text-sm text-gray-900 ">
                                                {
                                                    job.status === ExtractionJobStatus.Succeeded && (job.output_file_size !== 0) ? (
                                                            <Link
                                                                className="2xl:truncate block cursor-pointer p-2 md:p-4 hover:underline"
                                                                href={route('subtitle.edit', job)}>
                                                                <span
                                                                    className="line-clamp-2 2xl:line-clamp-none 2xl:inline">{job.file_name}</span>
                                                            </Link>) :
                                                        <div className="2xl:truncate block  p-2 md:p-4">
                                                            <span
                                                                className="line-clamp-2 2xl:line-clamp-none 2xl:inline">{job.file_name}</span>
                                                        </div>


                                                }

                                            </td>
                                            <td className="p-2 pl-4 md:p-4 text md:pl-5">
                                                <JobStatusIndicator job={job}/>
                                            </td>
                                            <td className="p-2 md:p-4 hidden md:table-cell text-sm font-normal text-gray-500 ">
                                                {ucFirst(job.method === 'ocr' ? 'vision' : job.method)}
                                            </td>
                                            <td className="p-2 md:p-4 text-sm font-normal text-gray-900 whitespace-nowrap dark:text-white">
                                                {job.file_duration ? secondsToDuration(job.file_duration) : 'N/A'}
                                            </td>
                                            <td className="p-2 md:p-4 hidden lg:table-cell text-sm font-normal text-gray-500 whitespace-nowrap">
                                                {job.file_size ? bytesToSize(job.file_size) : 'N/A'}
                                            </td>
                                            <td className="p-2 md:p-4 hidden lg:table-cell text-sm font-normal text-gray-500 whitespace-nowrap flex items-center">
                                                {getLanguageName(job.language)}
                                            </td>
                                            <td className="p-2 md:p-4 hidden xl:table-cell text-sm font-normal text-gray-500 whitespace-nowrap ">
                                                {moment(job.created_at).format('MMM D, h:mm A')}
                                            </td>
                                            <td className="text-start pr-2 md:px-4">

                                                {
                                                    !ExtractionJobStatus.isRunning(job.status) && (
                                                        <Dropdown onOpen={() => setTooltipOpen(false)}>
                                                            <Dropdown.Trigger>
                                                                {
                                                                    (isOpen) => (
                                                                        <Popover open={index === 0 && tooltipOpen}
                                                                                 placement='top'
                                                                                 content={<TranslationTooltip
                                                                                     onClose={closeToolTip}/>}>
                                                                            <button
                                                                                type="button"
                                                                                className=" menu-button flex text-sm leading-4 font-medium rounded-md text-gray-500 bg-white hover:text-gray-700 focus:outline-none transition ease-in-out duration-150"
                                                                            >
                                                                                <FontAwesomeIcon
                                                                                    className={`align-middle w-5 h-5 items-center p-2 text-sm font-medium text-center text-gray-900  rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none ${isOpen ? 'bg-gray-100' : 'bg-white'}`}
                                                                                    icon={faEllipsis}

                                                                                />
                                                                            </button>
                                                                        </Popover>
                                                                    )
                                                                }
                                                            </Dropdown.Trigger>
                                                            <Dropdown.Content className="sm:mt-1">

                                                                {
                                                                    (job.status === ExtractionJobStatus.Succeeded && job.output_file_size !== 0) && (
                                                                        <span>
                                                                            <Dropdown.Link isExternal={true}
                                                                                           title="Download"
                                                                                           href={`${route('job.download', job.id)}`}>
                                                                                <FontAwesomeIcon
                                                                                    className="text-gray-500  w-4 h-4 pr-2 "
                                                                                    icon={faCircleDown}/>
                                                                                <span>Download</span>
                                                                            </Dropdown.Link>
                                                                            <Dropdown.Link title="Edit"
                                                                                           href={route('subtitle.edit', job)}>
                                                                                <FontAwesomeIcon
                                                                                    className="text-gray-500 w-4 h-4 pr-2 "
                                                                                    icon={faPen}/>
                                                                                <span>Edit</span>
                                                                            </Dropdown.Link>
                                                                            <Dropdown.Item
                                                                                onClick={() => launchTranslation(job)}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                                     fill="none" viewBox="0 0 24 24"
                                                                                     strokeWidth="1.5"
                                                                                     stroke="currentColor"
                                                                                     className="text-gray-500  w-7 h-7 pr-2">
                                                                                    <path strokeLinecap="round"
                                                                                          strokeLinejoin="round"
                                                                                          d="m10.5 21 5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 0 1 6-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 0 1-3.827-5.802"/>
                                                                                </svg><span>Translate</span>
                                                                            </Dropdown.Item>
                                                                        </span>
                                                                    )
                                                                }
                                                                {

                                                                    job.status === ExtractionJobStatus.Failed &&
                                                                    (
                                                                        <Dropdown.Item title="Retry"
                                                                                       onClick={() => onRetry(job)}>
                                                                            <FontAwesomeIcon
                                                                                className="text-gray-500 w-4 h-4 pr-2 flex self-end"
                                                                                icon={faRotateRight}/>
                                                                            <span>Retry</span>
                                                                        </Dropdown.Item>
                                                                    )
                                                                }
                                                                {ExtractionJobStatus.isRunning(job.status) && (
                                                                    <Dropdown.Item
                                                                        onClick={() => handleCancellation(job)}>
                                                                        <FontAwesomeIcon
                                                                            className="text-gray-500 w-4 h-4 pr-2 "
                                                                            icon={faCircleXmark}/>
                                                                        <span>Cancel</span>
                                                                    </Dropdown.Item>
                                                                )}
                                                                {
                                                                    !ExtractionJobStatus.isRunning(job.status) &&
                                                                    job.status !== ExtractionJobStatus.Cancelled &&
                                                                    job.status !== ExtractionJobStatus.Failed &&
                                                                    job.method !== 'manual' &&
                                                                    (
                                                                        <Dropdown.Item title="Give Feedback"
                                                                                       onClick={() => onFeedback?.(job)}>
                                                                            <FontAwesomeIcon
                                                                                className="text-gray-500 w-4 h-4 pr-2 flex self-center"
                                                                                icon={faMessage}/>
                                                                            <span>Give Feedback</span>
                                                                        </Dropdown.Item>
                                                                    )
                                                                }
                                                                {
                                                                    !ExtractionJobStatus.isRunning(job.status) && (
                                                                        <Dropdown.Item onClick={() => handleDelete(job)}>
                                                                            <FontAwesomeIcon
                                                                                title="Delete"
                                                                                className="text-gray-500 w-4 h-4  pr-2 "
                                                                                icon={faTrashCan}/>
                                                                            <span>Delete</span>
                                                                        </Dropdown.Item>
                                                                    )
                                                                }
                                                            </Dropdown.Content>
                                                        </Dropdown>
                                                    )
                                                }

                                                {
                                                    ExtractionJobStatus.isRunning(job.status) && (
                                                        <Tooltip
                                                            content={<span>Cancel</span>}
                                                            placement="auto"
                                                        >
                                                            <button
                                                                type="button"
                                                                onClick={() => handleCancellation(job)}
                                                                className=" flex text-sm leading-4 font-medium rounded-md text-gray-500 hover:text-gray-700 focus:outline-none transition ease-in-out duration-150"
                                                            >
                                                                <FontAwesomeIcon
                                                                    className="text-gray-500 w-4 h-4 px-2 py-1 hover:text-red-600"
                                                                    icon={faCircleXmark}/>
                                                            </button>
                                                        </Tooltip>

                                                    )
                                                }


                                            </td>
                                        </tr>
                                    ))
                                }


                                </tbody>
                                {
                                    checked.size > 0 && (
                                        <tfoot>
                                        <tr>
                                            <td colSpan={9}
                                                className={`p-2 px-6 ${hasPages ? 'border-solid border-0 border-b border-gray-200' : ''} bg-gray-50 rounded-lg`}>
                                                <div className="flex grow items-center">
                                                    <p className="text-sm mr-4">
                                                    <span
                                                        className="font-normal text-gray-500 dark:text-gray-400"><span>{numChecked}</span> Selected</span>

                                                    </p>
                                                    <Dropdown>
                                                        <Dropdown.Trigger>
                                                            <SecondaryButton title="Download"><span
                                                                className="hidden md:inline">Bulk Actions</span>
                                                                <svg className="ms-2 inline h-5 w-5"
                                                                     xmlns="http://www.w3.org/2000/svg"
                                                                     viewBox="0 0 20 20" fill="currentColor">
                                                                    <path fillRule="evenodd"
                                                                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                                          clipRule="evenodd"></path>
                                                                </svg>
                                                            </SecondaryButton>
                                                        </Dropdown.Trigger>
                                                        <Dropdown.Content className="sm:mt-1 text-right" align={"left"}>
                                                            <Dropdown.Item onClick={handleBulkDelete}>
                                                                <FontAwesomeIcon
                                                                    icon={faTrashCan}
                                                                    className={`me-2`}
                                                                /> <span>Delete</span></Dropdown.Item>
                                                            <Dropdown.Item onClick={bulkDownload}>
                                                                <FontAwesomeIcon
                                                                    icon={faDownload}
                                                                    className={`me-2`}
                                                                /> <span>Download</span>
                                                            </Dropdown.Item>
                                                            <Dropdown.Item onClick={bulkTranslate}>
                                                                <span className={`flex items-center`}>
                                                                    <TranslateIcon/>
                                                                     <span>Translate</span>
                                                                </span>

                                                            </Dropdown.Item>
                                                            <Dropdown.Item onClick={() => setChecked(new Set())}>
                                                                <FontAwesomeIcon
                                                                    icon={faXmark}
                                                                    className={` md:me-2`}
                                                                /> <span>Deselect All</span>
                                                            </Dropdown.Item>

                                                        </Dropdown.Content>
                                                    </Dropdown>

                                                </div>
                                            </td>
                                        </tr>
                                        </tfoot>
                                    )
                                }
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {
                hasPages && (
                    <div className="flex justify-between items-center mt-4 mx-6 mb-5">
                        <PaginationControl perPageOptions={perPageOptions} source={paginatedJobs}/>
                    </div>
                )
            }
        </div>
    )
}

function SkeletonTableRow() {
    const widths = [
        'w-[50%] lg:w-[50%]',
        'w-[60%] lg:w-[55%]',
        'w-[70%] lg:w-[65%]',
        'w-[80%] lg:w-[70%]',
    ]
    const [width, setWidth] = useState('')

    useEffect(() => {
        const randomWidth = widths[Math.floor(Math.random() * widths.length)]
        setWidth(randomWidth)
    }, [])
    return (
        <tr>
            <td colSpan={9} className="p-4">
                <div className="flex w-full items-center-center py-1">
                    <div
                        className={`h-2 bg-gray-200 rounded-full w-4 mr-4 hidden md:block`}></div>
                    <div className={`h-2 bg-gray-200 rounded-full mr-4 ${width}`}></div>
                </div>
            </td>
        </tr>
    )
}

function TranslationTooltip({onClose}: { onClose?: () => void }) {
    return (
        <div className="p-5 w-64">
            <div className="flex">
                <svg xmlns="http://www.w3.org/2000/svg"
                     fill="none" viewBox="0 0 24 24"
                     strokeWidth="1.5"
                     stroke="currentColor"
                     className="text-gray-500  w-7 h-7 pr-2">
                    <path strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m10.5 21 5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 0 1 6-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 0 1-3.827-5.802"/>
                </svg>
                <h3 className="font-semibold mb-4  grow">Translation</h3>
                <FontAwesomeIcon
                    onClick={onClose}
                    title={"Close"}
                    className="text-gray-500  w-4 h-4 pr-2 cursor-pointer "
                    icon={faXmark}/>
            </div>

            <p className="text-sm">
                You can translate the subtitles to another language by clicking the menu button and selecting
                "Translate".
            </p>
        </div>
    )
}
