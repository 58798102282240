import {useEffect, useState} from "react";
import axios from "axios";

export type PaginatedSource<T> = {
    items: T[]
    total: number
    isLoading: boolean
    initialized: boolean
    routeName: string
    updateItems: (cb: (curr: T[]) => T[]) => void
    reloadItems: (extraParams?: Record<any, any>) => Promise<T[]>
    error: string | null
    pagination: {
        currentPage: number
        lastPage: number
        perPage: number
        total: number
    }
    nextPage: () => void
    prevPage: () => void
    setPage: (page: number, forceReload: boolean) => void,
    setPerPage: (perPage: number) => void
    lastLoadAt: number | null
}

const getStorageKey = (routeName: string) => {
    return `per_page_${routeName}`;
}

const getPerPage = (routeName: string, defaultVal: number | null) => {
    if(localStorage.getItem(getStorageKey(routeName))) {
        return Number(localStorage.getItem(getStorageKey(routeName)));
    }
    return defaultVal || DEFAULT_PER_PAGE;
}

const DEFAULT_PER_PAGE = 15;

const usePaginatedSource = <T>(
    routeName: string,
    initialPerPage: number | null = null,
    query: Record<any, any> = {}
): PaginatedSource<T> => {
    const [targetPage, setTargetPage] = useState(1);
    const [state, setState] = useState({
        perPage: 0,
        currentPage: 1,
        lastPage: 1,
        total: 0,
        items: [] as T[],
        isLoading: true,
        error: null as string | null,
        initialized: false,
        isMounted: false,
        lastLoadAt: Date.now()
    });

    useEffect(() => {
        setState(
            prev => ({
                ...prev, isMounted: true,
                perPage: getPerPage(routeName, initialPerPage)
            })
        )
    }, []);

    useEffect(() => {
        localStorage.setItem(getStorageKey(routeName), state.perPage.toString());
    }, [state.perPage]);

    function fetchItems(page: number, extraParams: Record<any, any> = {}): Promise<T[]> {
        setState(prev => ({...prev, isLoading: true, error: null}))
        return axios.get(route(routeName, {page, perPage: state.perPage, ...extraParams, ...query}))
            .then(response => {
                if (response.data.current_page === targetPage) {
                    setState(prev => ({
                        ...prev,
                        currentPage: response.data.current_page,
                        lastPage: response.data.last_page,
                        total: response.data.total,
                        items: response.data.data,
                        initialized: true
                    }));
                }
                return response.data.data;
            })
            .catch(error => {
                setState(prev => ({...prev, error: `Error fetching items: ${error}`}));
                return [];
            }).finally(() => {
                setState(prev => ({
                    ...prev,
                    isLoading: false,
                    lastLoadAt: Date.now()
                }));
            })
    }

    const setPerPage = (perPage: number) => {
        setState((curr) => (
            { ...curr, perPage })
        );
        setTargetPage(1);
    }

    const updateItems = (cb: (curr: T[]) => T[]) => {
        setState((curr) => ({
            ...curr,
            items: cb(curr.items)
        }))
    }

    const reloadItems = (extraParams: Record<any, any> = {}) => {
        return fetchItems(state.currentPage, extraParams);
    }

    useEffect(() => {
        if(state.isMounted) {
            fetchItems(targetPage)
        }
    }, [state.isMounted, state.perPage, targetPage, JSON.stringify(query)]);

    const nextPage = () => {
        if (state.currentPage < state.lastPage && targetPage === state.currentPage) {
            setTargetPage(state.currentPage + 1)
        }
    };

    const prevPage = () => {
        if (state.currentPage > 1 && targetPage === state.currentPage) {
            setTargetPage(state.currentPage - 1)
        }
    };

    const setPage = (page: number, forceReload: boolean = false) => {
        if (page === state.currentPage) {
            if (forceReload) {
                fetchItems(page);
            }
        } else if (page >= 1 && page <= state.lastPage) {
            setTargetPage(page);
        }
    }

    return {
        items: state.items,
        routeName,
        isLoading: state.isLoading,
        initialized: state.initialized,
        total: state.total,
        lastLoadAt: state.lastLoadAt,
        error: state.error,
        pagination: {
            currentPage: state.currentPage,
            lastPage: state.lastPage,
            total: state.total,
            perPage: state.perPage
        },
        updateItems,
        reloadItems,
        nextPage,
        prevPage,
        setPage,
        setPerPage
    }
};

export default usePaginatedSource;
