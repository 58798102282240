import moment from "moment";
import {formatCentsToDollars, formatMinutes, ucFirst} from "@/utils/textFormatting";
import React, {useEffect} from "react";
import usePaginatedSource from "@/hooks/usePaginatedSource";
import toast from "react-hot-toast";
import PaginationControl from "@/Components/PaginationControl";
import {useConfirm} from "@/Components/ConfirmDialog";
import EmptyPaymentsIcon from "@/Components/Icons/EmptyPaymentsIcon";


type PaymentSession = {
    id: string
    created_at: string
    provider: string
    payment_amount: number
    credit_amount: number
    status: 'pending' | 'completed' | 'failed'
}

const paymentProviderToMethod: Record<string, string> = {
    'stripe': 'Credit Card',
    'paypal': 'PayPal',
    'coinbase': 'Crypto'
}

export default function ({cancelPayment}: { cancelPayment: (paymentId: string) => void }) {
    const confirm = useConfirm()
    const paginatedSource = usePaginatedSource<PaymentSession>('billing.credits.payments');

    const handleCancellation = async (payment: any) => {
        const confirmed = await confirm({
            title: 'Cancel Payment',
            message: 'Are you sure you want to cancel this payment?',
        });
        if (!confirmed) {
            return;
        }
        cancelPayment(payment.id);
    }

    const {
        items,
        pagination,
        initialized,
        error,
        total,
    } = paginatedSource;

    useEffect(() => {
        if (error) {
            toast.error('Failed to load payments');
        }
    }, [error]);

    if(!initialized) {
        return null;
    }

    return (
        <div className="space-y-4">
            <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden">
                {
                    !total ? (
                        <div className="text-center flex flex-col justify-center items-center px-10 mb-28 mt-28">
                            <EmptyPaymentsIcon/>
                            <p className="text-xl text-gray-400">You have no payments</p>
                        </div>
                    ) : (
                        <div className="flex flex-col">
                            <div className="overflow-x-auto">
                                <div className="inline-block min-w-full align-middle">
                                    <table className="w-full leading-normal table-auto text-base">
                                        <thead>
                                        <tr>
                                            <th className="left-0 px-5 py-2 md:py-4 border-b bg-gray-50 border-gray-200 text-left text-sm font-bold text-gray-600 md:uppercase md:tracking-wider">
                                                Date
                                            </th>
                                            <th className="px-5 py-2 md:py-4 border-b bg-gray-50 border-gray-200 text-left text-sm font-semibold text-gray-600 md:uppercase md:tracking-wider">
                                                Method
                                            </th>
                                            <th className="px-5 py-2 md:py-4 border-b bg-gray-50 border-gray-200 text-left text-sm font-semibold text-gray-600 md:uppercase md:tracking-wider">
                                                Amount Paid
                                            </th>
                                            <th className="px-5 py-2 md:py-4 border-b bg-gray-50 border-gray-200 text-left text-sm font-semibold text-gray-600 md:uppercase md:tracking-wider">
                                                Credits Received
                                            </th>
                                            <th className="px-5 py-2 md:py-4 border-b bg-gray-50 border-gray-200 text-left text-sm font-semibold text-gray-600 md:uppercase md:tracking-wider">
                                                Status
                                            </th>
                                            <th className="px-5 py-2 md:py-4 border-b bg-gray-50 border-gray-200 text-left text-sm font-semibold text-gray-600 md:uppercase md:tracking-wider">
                                                Actions
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {items.map((transaction, idx) => (
                                            <tr key={transaction.id}>
                                                <td className={`bg-white px-5 whitespace-nowrap py-4 text-sm ${idx < items.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                                    {moment(transaction.created_at).format('MMM DD, YYYY h:mm A')}
                                                </td>
                                                <td className={`px-5 py-4 text-sm ${idx < items.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                                    {paymentProviderToMethod[transaction.provider]}
                                                </td>
                                                <td className={`px-5 py-4 text-sm ${idx < items.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                                    {formatCentsToDollars(transaction.payment_amount)}
                                                </td>
                                                <td className={`px-5 py-4 text-sm ${idx < items.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                                    {formatMinutes(transaction.credit_amount)}
                                                </td>
                                                <td className={`px-5 py-4 text-sm ${idx < items.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                                <span
                                                    className={`inline-flex text-xs font-medium me-2 px-2.5 py-0.5 rounded leading-5 ${
                                                        {
                                                            'pending': 'bg-orange-100 text-orange-800',
                                                            'failed': 'bg-red-100 text-red-800',
                                                            'completed': 'bg-green-100 text-green-800'
                                                        }[transaction.status] || 'bg-gray-100 text-gray-800'
                                                    }`}>
                                                    {ucFirst(transaction.status)}
                                                </span>
                                                </td>
                                                <td className={`px-5 py-4 text-sm ${idx < items.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                                    {transaction.status === 'pending' ? (
                                                        <button
                                                            type="button"
                                                            onClick={() => handleCancellation(transaction)}
                                                            className="px-4 py-2 duration-150 me-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100"
                                                        >
                                                            Cancel
                                                        </button>
                                                    ) : (
                                                        <a
                                                            target="_blank"
                                                            href={route('billing.invoice.download', transaction.id)}
                                                            className="px-4 py-2 duration-150 me-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100"
                                                        >
                                                            Invoice
                                                        </a>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="border-t border-gray-200">
                                <div className="flex justify-between items-center p-4">

                                    <PaginationControl source={paginatedSource}/>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
            }
