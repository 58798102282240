import moment from "moment";
import {formatMinutes, ucFirst} from "@/utils/textFormatting";
import {useEffect, useRef, useState} from "react";
import usePaginatedSource from "@/hooks/usePaginatedSource";
import toast from "react-hot-toast";
import PaginationControl from "@/Components/PaginationControl";
import {Tooltip} from "flowbite-react";

export type TransactionsTableProps = {
    transactions: any[]
    totalPages: number
    totalRecords: number
    nextPage: () => void
}

type Transaction = {
    id: string
    created_at: string
    type: string
    amount: number
    source: string
    transactionable_type: string,
    reference: string | null
}


export default function () {
    const paginatedSource = usePaginatedSource<Transaction>('billing.transactions');
    const [initialized, setInitialized] = useState(false);

    const {
        items,
        nextPage,
        prevPage,
        pagination,
        error,
        reloadItems
    } = paginatedSource;

    useEffect(() => {
        if (error) {
            toast.error('Failed to load transactions');
        }
    }, [error]);

    useEffect(() => {
        if (!initialized) {
            setInitialized(true);
        } else {
            const rafId = requestAnimationFrame(() => {
                window.scrollTo({top: 0, behavior: 'smooth'});
            });
            return () => cancelAnimationFrame(rafId);
        }
    }, [pagination.currentPage]);

    return (
        <div className="space-y-4">
            <div className="bg-white shadow rounded-lg overflow-hidden">
                <div className="flex flex-col">
                    <div className="overflow-x-auto">
                        <div className="inline-block min-w-full align-middle">
                            <table className="w-full leading-normal table-auto text-base">
                                <thead>
                                <tr>
                                    <th className="px-5 py-3 border-b bg-gray-50 border-gray-200 text-left text-sm font-bold text-gray-600 md:uppercase md:tracking-wider">
                                        Date
                                    </th>
                                    <th className="px-5 py-3 border-b bg-gray-50 border-gray-200 text-left text-sm font-semibold text-gray-600 md:uppercase md:tracking-wider">
                                        Type
                                    </th>
                                    <th className="px-5 py-3 border-b bg-gray-50 border-gray-200 text-left text-sm font-semibold text-gray-600 md:uppercase md:tracking-wider">
                                        Amount
                                    </th>
                                    <th className="px-5 py-3 border-b bg-gray-50 border-gray-200 text-left text-sm font-semibold text-gray-600 md:uppercase md:tracking-wider">
                                        Type
                                    </th>
                                    <th className="px-5 py-3 border-b bg-gray-50 border-gray-200 text-left text-sm font-semibold text-gray-600 md:uppercase md:tracking-wider">
                                        Reference
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {items.map((transaction, idx) => (
                                    <tr key={transaction.id}>
                                        <td className={`px-5 py-5 whitespace-nowrap text-sm ${idx < items.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                            {moment(transaction.created_at).format('MMM DD, YYYY h:mm:ss A')}
                                        </td>
                                        <td className={`px-5 py-5 text-sm ${idx < items.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                            <span className={
                                                `inline-flex text-xs font-medium me-2 px-2.5 py-0.5 rounded leading-5 ${transaction.type === 'debit' ? 'bg-blue-100 text-blue-800' : 'bg-green-100 text-green-800'}`
                                            }>
                                                {ucFirst(transaction.type)}
                                            </span>
                                        </td>
                                        <td className={`px-5 py-5 text-sm ${idx < items.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                            <span
                                                className={`${transaction.amount > 0 ? 'text-green-500' : ''} font-semibold`}>
                                                {formatMinutes(transaction.amount)}
                                            </span>
                                        </td>
                                        <td className={`px-5 py-5 text-sm ${idx < items.length - 1 ? 'border-b border-gray-200' : ''}`}>
                                            {ucFirst(transaction.type === 'debit' ?
                                                (transaction.transactionable_type?.includes('Translation') ? 'Translation' : 'Extraction') :
                                                transaction.source)
                                            }
                                        </td>
                                        <TransactionCell transaction={transaction} idx={idx} isLast={idx === items.length - 1}/>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="border-t border-gray-200">
                        <div className="flex justify-between items-center p-4">
                            <PaginationControl source={paginatedSource}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const TransactionCell = (
    { transaction, idx, isLast }: { transaction: Transaction, idx: number, isLast: boolean }
) => {
    const textRef = useRef<HTMLDivElement>(null);
    const [isOverflowing, setIsOverflowing] = useState(false);

    useEffect(() => {
        const checkOverflow = () => {
            if (textRef.current) {
                setIsOverflowing(
                    textRef.current.scrollWidth > textRef.current.clientWidth
                );
            }
        };

        checkOverflow();
        window.addEventListener('resize', checkOverflow);
        return () => window.removeEventListener('resize', checkOverflow);
    }, [transaction.reference]);

    return (
        <td className={`px-5 py-5 text-sm ${!isLast ? 'border-b border-gray-200' : ''}`}>
            {isOverflowing && transaction.reference ? (
                <Tooltip trigger="hover" content={<span className="max-w-60 break-all inline-block">{transaction.reference}</span>}>
                    <div ref={textRef} className="max-w-56 truncate">
                        {transaction.reference}
                    </div>
                </Tooltip>
            ) : (
                <div ref={textRef} className="max-w-56 truncate">
                    {transaction.reference ? transaction.reference : <span className="text-gray-500">N/A</span>}
                </div>
            )}
        </td>
    );
};
