import Modal from "@/Components/Modal";
import CloseIcon from "@/Components/Icons/CloseIcon";
import {SubtitleFormat} from "@/utils/subtitleUtils";
import {useState} from "react";
import PrimaryButton from "@/Components/PrimaryButton";

interface DownloadOption {
    format: 'srt' | 'vtt' | 'txt';
    label: string;
    icon: JSX.Element;
}

const downloadOptions: DownloadOption[] = [
    {
        format: 'srt',
        label: 'SRT',
        icon: (
            <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.5 3H7a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3v-9m-13 6h10m-10 3h7"
                      stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
                <path d="m13.5 3 4.5 4.5m-4.5-4.5v4.5h4.5" stroke="currentColor" strokeWidth="2"
                      strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        )
    },
    {
        format: 'vtt',
        label: 'VTT',
        icon: (
            <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.5 3H7a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3v-9m-13 6h10m-10 3h7"
                      stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
                <path d="m13.5 3 4.5 4.5m-4.5-4.5v4.5h4.5" stroke="currentColor" strokeWidth="2"
                      strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        )
    },
    {
        format: 'txt',
        label: 'TXT',
        icon: (
            <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6Z"
                      stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M14 2v6h6M8 13h8M8 17h8M8 9h2" stroke="currentColor" strokeWidth="2"
                      strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        )
    }
];

type DownloadModalProps = {
    show: boolean;
    onClose: () => void;
    onDownload: (format: SubtitleFormat, options: Record<string, any>) => void;
}

export default function DownloadModal({
    show,
    onClose,
    onDownload
}: DownloadModalProps) {
    const [selectedFormat, setSelectedFormat] = useState<SubtitleFormat>('srt');
    const [showAdvanced, setShowAdvanced] = useState(false);
    const [includeTimestamps, setIncludeTimestamps] = useState(true);
    const [closeSmallGaps, setCloseSmallGaps] = useState(false);
    const [lineSpacing, setLineSpacing] = useState(2);

    const handleDownload = () => {
        onDownload(selectedFormat, {includeTimestamps, closeSmallGaps, lineSpacing});
        onClose();
    }

    return (
        <Modal maxWidth="sm" show={show} onClose={onClose}>
            <div id="default-modal" className="w-full">
                <div className="flex items-center pt-4 px-5">
                    <div className="w-8 h-8"></div>
                    <div className="flex-grow flex justify-center"><h3
                        className="text-lg font-semibold text-gray-900">Download Subtitles</h3></div>
                    <button type="button"
                            onClick={onClose}
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center"
                            data-modal-hide="default-modal">
                        <CloseIcon/>
                        <span className="sr-only">Close modal</span>
                    </button>
                </div>
                <div className="px-7 pt-7 pb-5">
                    <div className="mb-6">
                        <label className="block text-sm text-gray-800 mb-4">Download Format</label>
                        <div className="flex gap-3">
                            {downloadOptions.map((option) => (
                                <button
                                    key={option.format}
                                    onClick={() => setSelectedFormat(option.format)}
                                    className={`flex-1 flex flex-col items-center justify-center px-3 py-2 rounded-lg border-2 transition-all cursor-pointer
                                        ${selectedFormat === option.format
                                        ? 'border-blue-600 bg-blue-50 text-blue-600'
                                        : 'border-gray-200 hover:border-gray-300 text-gray-600 hover:bg-gray-50'
                                    }`}
                                >
                                    <span className="mb-1">
                                        {option.icon}
                                    </span>
                                    <span className="text-sm font-medium">{option.label}</span>
                                </button>
                            ))}
                        </div>
                    </div>

                    <div className="mb-6 border-t pt-4">
                        <button
                            onClick={() => setShowAdvanced(!showAdvanced)}
                            className="text-sm text-blue-600 hover:text-blue-700 font-medium flex items-center"
                        >
                            <svg
                                className={`w-4 h-4 mr-1 transform transition-transform ${showAdvanced ? 'rotate-90' : ''}`}
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7"/>
                            </svg>
                            Advanced Options
                        </button>

                        {showAdvanced && (
                            <div className="mt-4 space-y-4">
                                {selectedFormat === 'txt' ? (
                                    <div>
                                        <label className="flex items-center space-x-3">
                                            <input
                                                type="checkbox"
                                                checked={includeTimestamps}
                                                onChange={(e) => setIncludeTimestamps(e.target.checked)}
                                                className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                                            />
                                            <span className="text-sm text-gray-600">Include timing information</span>
                                        </label>
                                        <div className="flex items-center space-x-3 mt-3">
                                            <label className="text-sm text-gray-600">Line spacing</label>
                                            <input
                                                type="number"
                                                min="1"
                                                max="5"
                                                value={lineSpacing}
                                                onChange={(e) => setLineSpacing(Number(e.target.value))}
                                                className="w-16 px-2 py-1 text-sm border border-gray-300 rounded focus:ring-blue-500 focus:border-blue-500"
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <label className="flex items-center space-x-3">
                                        <input
                                            type="checkbox"
                                            checked={closeSmallGaps}
                                            onChange={(e) => setCloseSmallGaps(e.target.checked)}
                                            className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                                        />
                                        <span
                                            className="text-sm text-gray-600">Close small gaps between subtitles {`<1s`}</span>
                                    </label>
                                )}
                            </div>
                        )}
                    </div>

                    <div className="flex justify-end space-x-3 pt-2">
                        <PrimaryButton
                            onClick={handleDownload}
                            className="flex items-center w-full justify-center"
                        >
                            <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                      d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"/>
                            </svg>
                            Download
                        </PrimaryButton>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
